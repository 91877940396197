<!--解决方案-->
<template>
  <div class="solution-container">
    <new-cloude-header/>
    <div class="solution-header">
      <div class="solution-title">
        <p class="large-title">数智化解决方案</p>
        <p class="normal-p">针对各行业、各领域提供成熟可靠的解决方案</p>
      </div>
    </div>
    <div class="float-layer">
      <el-row>
        <el-col :span="6">
          <div>
            <p class="bold-p">全链</p>
            <p class="darkness-p">提供全链业务数字化场景的产品服务体系</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <p class="bold-p">敏捷</p>
            <p class="darkness-p">强大的PaaS能力，帮助企业敏捷高效交付</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <p class="bold-p">开放</p>
            <p class="darkness-p">系统满足端到端的服务能力生态</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <p class="bold-p">可靠</p>
            <p class="darkness-p">强大的技术中台能力支撑全链业务</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--热门行业解决方案 -->
    <div class="hot-industry-solution">
      <div class="solution-hot-industry">
        <p class="hot-title">热门行业解决方案</p>
        <p class="hot-tips">为企业提供商业数字化全栈解决方案，满足精准化业务需求</p>
      </div>
      <div class="hot-industry">
        <el-row :gutter="25">
          <el-col :span="6">
            <div class="hot-industry-1" @click="solutionDetails('1','1001')"/>
            <div class="bottom-title">机械行业</div>
          </el-col>
          <el-col :span="6">
            <div @click="solutionDetails('2','2001')">
              <div class="hot-industry-2"/>
              <div class="bottom-title">食品行业</div>
            </div>
            <div style="margin-top: 25px;" @click="solutionDetails('10','10001')">
              <div class="hot-industry-3"/>
              <div class="bottom-title">家居行业</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="solutionDetails('9','9001')">
              <div class="hot-industry-4"/>
              <div class="bottom-title">金融行业</div>
            </div>
            <div style="margin-top: 25px;" @click="solutionDetails('7','7001')">
              <div class="hot-industry-5"/>
              <div class="bottom-title">汽车行业</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="solutionDetails('5','5001')">
              <div class="hot-industry-6"/>
              <div class="bottom-title">家电行业</div>
            </div>
            <div style="margin-top: 25px;" @click="solutionDetails('4','4001')">
              <div class="hot-industry-7"/>
              <div class="bottom-title">医药行业</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--行业解决方案 -->
    <div class="industry-solution">
      <div class="industry-content">
        <div class="solution-hot-industry">
          <p class="hot-title">行业解决方案</p>
          <p class="hot-tips">助力百行千业数字化转型升级</p>
        </div>
        <solution/>
      </div>
    </div>
    <!--五大产品优势-->
    <div class="product-advantages">
      <div class="advantages-container">
        <div class="solution-hot-industry">
          <p class="hot-title">五大产品优势</p>
          <p class="hot-tips">赋能传统生产制造企业，实现数字化转型升级</p>
        </div>
        <div class="advantages-content">
          <div class="advantages-col">
            <div>
              <div class="product-1"/>
              <p>专业定制化解决方案</p>
            </div>
          </div>
          <div class="advantages-col">
            <div>
              <div class="product-2"/>
              <p>服务价值高</p>
            </div>
          </div>
          <div class="advantages-col">
            <div>
              <div class="product-3"/>
              <p>功能齐全、操作简单</p>
            </div>
          </div>
          <div class="advantages-col">
            <div>
              <div class="product-4"/>
              <p>呈现直观</p>
            </div>
          </div>
          <div class="advantages-col">
            <div>
              <div class="product-5"/>
              <p>安全保障</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <new-cloude-footer/>
  </div>
</template>
<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import solution from '@/components/solution/index'
import NewCloudeFooter from "@/views/home/newCloudeFooter";
export default {
  name: "index",
  components: {NewCloudeFooter, NewCloudeHeader,solution},
  data() {
    return {
      activeName:'1',
    }
  },
  methods:{
    /*具体解决方案*/
    solutionDetails(industryId,id){
      this.$router.push({
        path: '/solutionDetails',
        query: {
          industryId: industryId,
          id:id
        }
      })
    },
  }
}
</script>
<style scoped>
.solution-container >>> .el-aside {
  background: #fff;
}
.solution-container >>> .el-tabs__active-bar {
  background-color: #1935DE;
  width: 3px;
  height: 20px !important;
  top: 20px;
}
.solution-container >>> .el-tabs__item.is-active {
  color: #1935DE;
}
.solution-container >>> .el-tabs__item {
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #333333;
  font-family: 'SourceHanSansCN';
  font-size: 18px;
}
.solution-container >>> .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
.solution-container >>> .el-tabs__nav-wrap::after {
  background-color: #fff;
}
.solution-container >>> .el-tabs--left .el-tabs__header.is-left {
  margin-right: 2px;
}
.solution-container >>> .el-card__body,
.solution-container >>> .el-main {
  padding: 0px 0px 0px 20px;
}
</style>
<style scoped lang="scss">
.solution-container{
  .solution-header{
    height: 400px;
    background: url("~@/assets/img/solution/solutionHeaderBackground.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .solution-title{
      width: 1200px;
      margin: auto;
      margin-top: 120px;
      padding-left: 80px;
      .large-title{
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
        margin-block-end: 20px;
        margin-block-start: 0px;
        font-family: 'SourceHanSansCN';
      }
      .normal-p{
        font-size: 16px;
        color: #FFFFFF;
        margin-block-end: 0px;
        margin-block-start: 0px;
        font-family: 'SourceHanSansCN';
      }
    }
  }
  .float-layer{
    width: 1180px;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px 0px rgba(169, 169, 169, 0.32);
    padding: 30px 10px;
    margin: auto;
    margin-top: -90px;
    position: sticky;
    .el-col-6 {
      width: 25%;
      padding: 0px 15px;
      border-right: 1px solid #DDDDDD;
      .darkness-p{
        font-size: 14px;
        color: #666666;
        font-family: 'SourceHanSansCN';
        margin-block-start: 10px;
        margin-block-end: 0px;
      }
      .bold-p{
        font-size: 18px;
        color: #333333;
        font-family: 'SourceHanSansCN';
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }
    .el-col-6:last-child{
      border-right: none;
    }
  }
  /*热门行业解决方案*/
  .hot-industry-solution{
    width: 1200px;
    margin: auto;
    padding: 60px 0px;
    background: #FFFFFF;
    .hot-industry{
      margin-top: 40px;
      .hot-industry-1{
        height: 425px;
        background: url("~@/assets/img/solution/机械行业.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .hot-industry-2{
        height: 200px;
        background: url("~@/assets/img/solution/食品行业.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .hot-industry-3{
        height: 200px;
        background: url("~@/assets/img/solution/组家居行业1545.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .hot-industry-4{
        height: 200px;
        background: url("~@/assets/img/solution/金融行业.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .hot-industry-5{
        height: 200px;
        background: url("~@/assets/img/solution/汽车行业.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .hot-industry-6{
        height: 200px;
        background: url("~@/assets/img/solution/家电行业.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .hot-industry-7{
        height: 200px;
        background: url("~@/assets/img/solution/医药行业.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .bottom-title{
        background: rgba(50, 69, 93, 0.5);
        color: #FFFFFF;
        font-size: 18px;
        font-family: "SourceHanSansCN";
        padding: 10px 20px;
        margin-top: -45px;
        cursor: pointer;
      }
    }
  }
  .solution-hot-industry{
    text-align: center;
    .hot-title{
      font-size: 30px;
      font-family: 'SourceHanSansCN';
      color: #333333;
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-weight: 500;
    }
    .hot-tips{
      font-family: 'SourceHanSansCN';
      color: #666666;
      font-size: 18px;
      margin-block-start: 10px;
      margin-block-end: 0px;
    }
  }
  /*行业解决方案*/
  .industry-solution{
    background: #F5FAFE;
    .industry-content{
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
    }
  }
  /*五大产品优势*/
  .product-advantages{
    background: #FFFFFF;
    .advantages-container{
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .advantages-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .advantages-col{
          border: 1px solid #C6D6E4;
          height: 230px;
          width: 230px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          .product-1{
            background: url("~@/assets/img/solution/专业定制化解决方案.png");
            height: 90px;
            width: 104px;
            background-size: 100% 100%;
            margin: auto;
          }
          .product-2{
            background: url("~@/assets/img/solution/ys01.png");
            height: 90px;
            width: 104px;
            background-size: 100% 100%;
            margin: auto;
          }
          .product-3{
            background: url("~@/assets/img/solution/ys04.png");
            height: 90px;
            width: 104px;
            background-size: 100% 100%;
            margin: auto;
          }
          .product-4{
            background: url("~@/assets/img/solution/ys02.png");
            height: 90px;
            width: 104px;
            background-size: 100% 100%;
            margin: auto;
          }
          .product-5{
            background: url("~@/assets/img/solution/ys06.png");
            height: 90px;
            width: 104px;
            background-size: 100% 100%;
            margin: auto;
          }
          p{
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 10px;
          }
        }
      }
    }
  }
}
</style>
