<template>
  <div class="solution--container">
    <el-container>
      <el-aside width="240">
        <el-tabs tab-position="left" v-model="activeName" @tab-click="handleClick">
          <template v-for="(tabs,index) of solution">
            <el-tab-pane :name="tabs.industryId">
              <span slot="label">{{ tabs.industry }}</span>
            </el-tab-pane>
          </template>
        </el-tabs>
      </el-aside>
      <el-main>
        <div class="solution-list" id="solution">
          <template v-for="(list,index) of solution">
            <div :class="'solution-li solution-li-'+list.industryId" :ref="'solution'+list.industryId">
              <p class="li-title">{{ list.industry }}</p>
              <template v-if="list.children.length>0">
                <el-row :gutter="20" style="margin-top: 20px;">
                  <template v-for="children of list.children">
                    <el-col :span="8">
                      <div class="solution-col" @click="solutionDetails(list.industryId,children.id)">
                        <p class="col-title">{{ children.title }}</p>
                        <p class="col-introduce">{{ children.briefIntroduction }}</p>
                      </div>
                    </el-col>
                  </template>
                </el-row>
              </template>
            </div>
          </template>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import solution from "@/static/solution.json";

export default {
  name: "index",
  data() {
    return {
      solution: solution,
      activeName:'1',
      scrollBox:null,
    }
  },
  methods:{
    /*具体解决方案*/
    solutionDetails(industryId,id){
      this.$router.push({
        path: '/solutionDetails',
        query: {
          industryId: industryId,
          id:id
        }
      })
    },
    handleClick(tab) {
      this.activeName = tab.name;
      let hei=0;
      let jump=document.getElementsByClassName('solution-li');
      for (let a=0;a<(tab.name*1)-1;a++){
        hei+=jump[a].offsetHeight;
      }
      document.getElementById('solution').scrollTo({
        top: hei,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    /*let that=this;
    this.scrollBox = document.getElementById('solution')
    let topArr = [], hei=0;
    let jump=document.getElementsByClassName('solution-li');
    for (let a=0;a<jump.length;a++){
      hei+=jump[a].offsetHeight;
      topArr.push({scrollTop:hei,industryId:a+1});
    }
    this.scrollBox.addEventListener('scroll', () => {
      for (let i = 0; i < topArr.length; i++) {
        if (that.scrollBox.scrollTop <= topArr[i].scrollTop) { // 根据滚动距离判断应该滚动到第几个导航的位置
          that.activeName = topArr[i].industryId;
          break
        }
      }
    })*/
  }
}
</script>
<style scoped>
.solution--container >>> .el-aside {
  background: #fff;
}

.solution--container >>> .el-tabs__active-bar {
  background-color: #1935DE;
  width: 3px;
  height: 20px !important;
  top: 20px;
}

.solution--container >>> .el-tabs__item.is-active {
  color: #1935DE;
}

.solution--container >>> .el-tabs__item {
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #333333;
  font-family: 'SourceHanSansCN';
  font-size: 18px;
}

.solution--container >>> .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

.solution--container >>> .el-tabs__nav-wrap::after {
  background-color: #fff;
}

.solution--container >>> .el-tabs--left .el-tabs__header.is-left {
  margin-right: 2px;
}

.solution--container >>> .el-card__body,
.solution--container >>> .el-main {
  padding: 0px 0px 0px 20px;
}
</style>
<style scoped lang="scss">
.solution--container {
  padding: 30px 0px;

  .solution-list {
    height: 610px;
    overflow-y: auto;

    .li-title {
      padding-left: 20px;
      border-left: 3px solid #1935DE;
      font-size: 18px;
      font-family: "SourceHanSansCN";
      margin-block-end: 0px;
      margin-block-start: 0px;
      line-height: 18px;
    }

    .solution-col {
      border: 1px solid #C6D6E4;
      padding: 30px 20px;
      margin-bottom: 20px;
      background: #FFFFFF;
      height: 90px;
      cursor: pointer;

      .col-title {
        font-family: 'SourceHanSansCN';
        font-size: 18px;
        color: #333333;
        margin-block-start: 0px;
        margin-block-end: 20px;
      }

      .col-introduce {
        font-size: 14px;
        font-family: 'SourceHanSansCN';
        color: #333333;
        margin-block-start: 0px;
        margin-block-end: 0px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .solution-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
</style>
